/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/covermodels/skateboarding.gltf -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Badan: THREE.Mesh
    Cube013: THREE.Mesh
    Cube014: THREE.Mesh
    Cube015: THREE.Mesh
    Cylinder002: THREE.Mesh
    Eyebrow001: THREE.Mesh
    Sphere005: THREE.Mesh
    Cube014_1: THREE.Mesh
    Cube014_2: THREE.Mesh
    Cube014_3: THREE.Mesh
    Cube014_4: THREE.Mesh
    Cube015_1: THREE.Mesh
    Cube015_2: THREE.Mesh
    Cube015_3: THREE.Mesh
    Cube015_4: THREE.Mesh
    Sphere: THREE.Mesh
    Plane: THREE.Mesh
    Plane_1: THREE.Mesh
    Vert001: THREE.Mesh
    Vert001_1: THREE.Mesh
    Badan002: THREE.Mesh
    Badan003: THREE.Mesh
    Badan004: THREE.Mesh
    Cylinder001: THREE.Mesh
    Cylinder006_1: THREE.Mesh
    Cylinder006_2: THREE.Mesh
    Cylinder005: THREE.Mesh
    Cylinder009: THREE.Mesh
    Cylinder009_1: THREE.Mesh
    Cylinder010: THREE.Mesh
    Cylinder010_1: THREE.Mesh
    Cylinder011: THREE.Mesh
    Cylinder011_1: THREE.Mesh
    Cube001: THREE.Mesh
  }
  materials: {
    Skin: THREE.MeshStandardMaterial
    Black: THREE.MeshStandardMaterial
    ['Skin.001']: THREE.MeshStandardMaterial
    ['Blue.001']: THREE.MeshStandardMaterial
    Yellow: THREE.MeshStandardMaterial
    White: THREE.MeshStandardMaterial
    Red: THREE.MeshStandardMaterial
    ['Black.002']: THREE.MeshStandardMaterial
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function Skateboarding(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/covermodels/skateboarding.gltf') as GLTFResult
  return (
    <group {...props} dispose={null} scale={1.1}>
      <mesh geometry={nodes.Badan.geometry} material={materials.Skin} scale={0.736} />
      <mesh geometry={nodes.Cube013.geometry} material={materials.Black} position={[-0.893, -0.046, -0.151]} rotation={[0.325, 0.313, -0.496]} />
      <mesh geometry={nodes.Cube014.geometry} material={materials['Skin.001']} position={[-0.893, -0.046, -0.151]} rotation={[0.325, 0.313, -0.496]} scale={0.736} />
      <mesh geometry={nodes.Cube015.geometry} material={materials.Skin} position={[-0.893, -0.046, -0.151]} rotation={[0.325, 0.313, -0.496]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials.Skin} position={[-0.9, -0.061, -0.153]} rotation={[0.325, 0.313, -0.496]} scale={[1.101, 1, 1]} />
      <mesh geometry={nodes.Eyebrow001.geometry} material={materials.Black} position={[-0.893, -0.046, -0.151]} rotation={[0.325, 0.313, -0.496]} />
      <mesh geometry={nodes.Sphere005.geometry} material={materials.Black} position={[-0.893, -0.046, -0.151]} rotation={[0.325, 0.313, -0.496]} />
      <group position={[-0.051, 0.137, 0.087]} rotation={[0.675, 1.14, -0.723]} scale={1.17}>
        <mesh geometry={nodes.Cube014_1.geometry} material={materials['Blue.001']} />
        <mesh geometry={nodes.Cube014_2.geometry} material={materials.Black} />
        <mesh geometry={nodes.Cube014_3.geometry} material={materials.Yellow} />
        <mesh geometry={nodes.Cube014_4.geometry} material={materials.White} />
      </group>
      <group position={[-0.675, 0.299, -0.197]} rotation={[0.403, 0.977, -0.519]} scale={1.17}>
        <mesh geometry={nodes.Cube015_1.geometry} material={materials['Blue.001']} />
        <mesh geometry={nodes.Cube015_2.geometry} material={materials.Black} />
        <mesh geometry={nodes.Cube015_3.geometry} material={materials.Yellow} />
        <mesh geometry={nodes.Cube015_4.geometry} material={materials.White} />
      </group>
      <mesh geometry={nodes.Sphere.geometry} material={materials.Red} position={[0.15, 1.995, 0.18]} rotation={[0.325, 0.313, -0.496]} scale={0.905} />
      <group position={[0.027, 0.123, -0.005]} rotation={[0.014, 0.217, -0.252]} scale={[0.929, 0.929, 0.991]}>
        <mesh geometry={nodes.Plane.geometry} material={materials.Yellow} />
        <mesh geometry={nodes.Plane_1.geometry} material={materials.Red} />
      </group>
      <group scale={0.736}>
        <mesh geometry={nodes.Vert001.geometry} material={materials.Red} />
        <mesh geometry={nodes.Vert001_1.geometry} material={materials.Yellow} />
      </group>
      <mesh geometry={nodes.Badan002.geometry} material={materials.Black} scale={0.736} />
      <mesh geometry={nodes.Badan003.geometry} material={materials.Black} scale={0.736} />
      <mesh geometry={nodes.Badan004.geometry} material={materials.Black} scale={0.736} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials.Black} position={[0.316, 0.065, -0.022]} rotation={[0.012, -0.006, -0.003]} />
      <group position={[-0.504, 0.28, 0.313]} rotation={[0, 0.225, 0]} scale={1.055}>
        <mesh geometry={nodes.Cylinder006_1.geometry} material={materials.Black} />
        <mesh geometry={nodes.Cylinder006_2.geometry} material={materials['Blue.001']} />
      </group>
      <mesh geometry={nodes.Cylinder005.geometry} material={materials.Black} position={[-0.534, 0.28, 0.181]} />
      <group position={[0.347, 0.063, 0.128]} rotation={[0, 0.225, 0]} scale={1.055}>
        <mesh geometry={nodes.Cylinder009.geometry} material={materials.Black} />
        <mesh geometry={nodes.Cylinder009_1.geometry} material={materials['Blue.001']} />
      </group>
      <group position={[-0.571, 0.28, 0.022]} rotation={[0, 0.224, -Math.PI]} scale={-1.055}>
        <mesh geometry={nodes.Cylinder010.geometry} material={materials.Black} />
        <mesh geometry={nodes.Cylinder010_1.geometry} material={materials['Blue.001']} />
      </group>
      <group position={[0.276, 0.063, -0.181]} rotation={[0, 0.224, -Math.PI]} scale={-1.055}>
        <mesh geometry={nodes.Cylinder011.geometry} material={materials.Black} />
        <mesh geometry={nodes.Cylinder011_1.geometry} material={materials['Blue.001']} />
      </group>
      <mesh geometry={nodes.Cube001.geometry} material={materials['Black.002']} position={[0.098, 1.856, 0.189]} rotation={[0.325, 0.313, -0.496]} scale={0.793} />
    </group>
  )
}

useGLTF.preload('/covermodels/skateboarding.gltf')
